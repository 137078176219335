import FloodFillActionTypes from './flood-fill.types';

const INITIAL_STATE = {
  data: null,
  isFetching: false,
  errorMessage: undefined,
};

const floodFillReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FloodFillActionTypes.FLOOD_FILL_START:
    case FloodFillActionTypes.DELETE_START:
      return {
        ...state,
        isFetching: true,
      };

    case FloodFillActionTypes.FLOOD_FILL_SUCCESS:
    case FloodFillActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.value,
      };

    case FloodFillActionTypes.FLOOD_FILL_ERROR:
    case FloodFillActionTypes.DELETE_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default floodFillReducer;
