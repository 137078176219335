import FloodFillActionTypes from './flood-fill.types';

export const floodFillStart = () => ({
  type: FloodFillActionTypes.FLOOD_FILL_START,
});

export const floodFillSuccess = (data: any) => ({
  type: FloodFillActionTypes.FLOOD_FILL_SUCCESS,
  payload: data,
});

export const floodFillError = (errorMessage: any) => ({
  type: FloodFillActionTypes.FLOOD_FILL_ERROR,
  payload: errorMessage,
});

export const deleteStart = () => ({
  type: FloodFillActionTypes.DELETE_START,
});

export const deleteSuccess = (data: any) => ({
  type: FloodFillActionTypes.DELETE_SUCCESS,
  payload: data,
});

export const deleteError = (errorMessage: any) => ({
  type: FloodFillActionTypes.DELETE_ERROR,
  payload: errorMessage,
});
