// @ts-nocheck
import { takeEvery, all, call, put } from 'redux-saga/effects';
import axios from 'axios';

import {
  fetchSchoolSuccess,
  fetchSchoolError,
  postSchoolPerfSuccess,
  postSchoolPerfError,
  editSchoolPerfSuccess,
  editSchoolPerfError,
} from './school-performance.actions';

import SchoolPerformanceActionTypes from './school-performance.types';

export function* fetchDataAsync({ payload, accessToken }) {
  const { school, academicYear } = payload;

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    const data = yield axios.get(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/SchoolPerformance?schools=${school}&academicYear=${academicYear}&take=200&orderBy=adddate&orderDirection=DESC`,
      config
    );

    yield put(fetchSchoolSuccess(data));
  } catch (error) {
    yield put(fetchSchoolError(error.message));
  }
}

export function* fetchSchoolStart() {
  yield takeEvery(
    SchoolPerformanceActionTypes.FETCH_SCHOOL_START,
    fetchDataAsync
  );
}

export function* postDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const {
    school,
    academicYear,
    performanceTypeId,
    performanceCategoryId,
    value,
  } = payload;

  try {
    const data = yield axios.post(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/SchoolPerformance?schools=${school}&academicYear=${academicYear}&performanceTypeId=${performanceTypeId}&performanceCategoryId=${performanceCategoryId}&value=${value}`,
      {},
      config
    );

    yield put(postSchoolPerfSuccess(data));
  } catch (error) {
    yield put(postSchoolPerfError(error.message));
  }
}

export function* postSchoolPerfStart() {
  yield takeEvery(
    SchoolPerformanceActionTypes.POST_SCHOOL_PERF_START,
    postDataAsync
  );
}

export function* editDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const {
    school,
    academicYear,
    performanceTypeId,
    performanceCategoryId,
    value,
    id,
  } = payload;

  try {
    const data = yield axios.post(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/SchoolPerformance?schools=${school}&academicYear=${academicYear}&performanceTypeId=${performanceTypeId}&performanceCategoryId=${performanceCategoryId}&value=${value}&id=${id}`,
      {},
      config
    );

    yield put(editSchoolPerfSuccess(data));
  } catch (error) {
    yield put(editSchoolPerfError(error.message));
  }
}

export function* editSchoolPerfStart() {
  yield takeEvery(
    SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_START,
    editDataAsync
  );
}

export function* schoolSagas() {
  yield all([
    call(fetchSchoolStart),
    call(postSchoolPerfStart),
    call(editSchoolPerfStart),
  ]);
}
