/**
 *
 * Asynchronously loads the component for HomePage
 *
 */

import loadable from '../../utils/loadable';
import { SchoolPerformanceProps } from './index';

export default loadable<SchoolPerformanceProps>(() => import('./index'));
