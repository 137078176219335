import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import settingsReducer from './settings/settings.reducer';
import filtersReducer from './filters/filters.reducer';
import pupilReducer from './pupil/pupil.reducer';
import gradeReducer from './grade/grade.reducer';
import toggleReducer from './toggle/toggle.reducer';
import yearZeroReducer from './year-zero/year-zero.reducer';
import schoolPerformanceReducer from './school-performance/school-performance.reducer';
import accessTokenReducer from './access-token/access-token.reducer';
import classReducer from './class/class.reducer';
import floodFillReducer from './flood-fill/flood-fill.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings', 'filters', 'accessToken', 'class', 'toggle'],
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  filters: filtersReducer,
  pupils: pupilReducer,
  grades: gradeReducer,
  toggle: toggleReducer,
  yearZero: yearZeroReducer,
  schoolPerformance: schoolPerformanceReducer,
  accessToken: accessTokenReducer,
  class: classReducer,
  floodFill: floodFillReducer,
});

export default persistReducer(persistConfig, rootReducer);
