import { createGlobalStyle } from 'styled-components';

const lightPurple = '#BFA8E0';

const GlobalStyle = createGlobalStyle`
  body {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0;
    padding: 0;
    background: ${lightPurple};
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;

    #root{
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      height: 100%;
      overflow: hidden;
 
    }
  }
`;

export default GlobalStyle;
