const SettingsActionTypes = {
  FETCH_DATA_START: 'FETCH_DATA_START',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_ERROR: 'FETCH_DATA_ERROR',
};

export default SettingsActionTypes;

export interface School {
  id: number;
  name: string;
  institution: string;
  label: string;
  adGroupId: string;
}
