// @ts-nocheck
import SettingsActionTypes from './settings.types';

export const fetchDataStart = (data) => ({
  type: SettingsActionTypes.FETCH_DATA_START,
  payload: data,
});

export const fetchDataSuccess = (data) => ({
  type: SettingsActionTypes.FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataError = (errorMessage) => ({
  type: SettingsActionTypes.FETCH_DATA_ERROR,
  payload: errorMessage,
});
