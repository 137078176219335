// @ts-nocheck
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import FiltersActionTypes from '../../redux/filters/filters.types';

import { useNavigate, useLocation } from 'react-router-dom';

// styles
import {
  Wrapper,
  MainHeader,
  Logo,
  Title,
  User,
  Ul,
  Initials,
  SubHeader,
  Select,
  Button,
} from './styles';

import logo from '../../images/header-logo.svg';

console.log(logo);

const Header = ({ name }: HeaderProps) => {
  const settings = useSelector((state) => state.settings);
  const filters = useSelector((state) => state.filters);

  const { schools, academicYears } = settings;
  const { school, academicYear } = filters;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const returnSchoolName = () =>
    schools && school && schools.filter((el) => el.id === Number(school));

  const returnInitials = (name: string) => {
    if (!name) return;
    const array = name && name.split(' ');

    return array?.map((x) => x.charAt(0)).join('');
  };

  const SchoolPerformance = () => (
    <Button onClick={() => navigate('/school-performance')}>
      School Performance
    </Button>
  );

  const BackToAssessments = () => (
    <Button onClick={() => navigate('/pupil-tracking')}>Assessments</Button>
  );

  if (location.pathname === '/') {
    return null;
  }

  return (
    <Wrapper>
      <MainHeader>
        <Logo imageUrl={logo} />

        <Title onClick={() => navigate('/pupil-tracking')}>
          Pupil Tracking System
        </Title>
        <User>
          <Ul>
            <li>{name && name}</li>
            <li>{returnSchoolName()?.name}</li>
          </Ul>

          <Initials>{returnInitials(name)}</Initials>
        </User>
      </MainHeader>

      <SubHeader>
        <Select
          defaultValue={school}
          onChange={(e) =>
            dispatch({
              type: FiltersActionTypes.SET_SCHOOL,
              payload: { [e.target.name]: e.target.value },
            })
          }
          name={'school'}
        >
          {schools?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>

        <Select
          defaultValue={academicYear}
          name={'academicYear'}
          onChange={(e) =>
            dispatch({
              type: FiltersActionTypes.SET_ACADEMIC_YEAR,
              payload: { [e.target.name]: e.target.value },
            })
          }
        >
          {academicYears?.map(({ id, label }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </Select>

        {location.pathname === '/school-performance' ? (
          <BackToAssessments />
        ) : (
          <SchoolPerformance />
        )}
      </SubHeader>
    </Wrapper>
  );
};

interface HeaderProps {
  name?: string;
}

export default Header;
