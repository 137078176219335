import YearZeroActionTypes from './year-zero.types';

const INITIAL_STATE = {
  CandL: [
    {
      id: 35,
      name: 'Listening, Attention and Understanding',
      active: false,
      areaId: 1,
      areaName: 'C & L',
      isPrime: true,
    },
    {
      id: 27,
      name: 'Speaking',
      active: false,
      areaId: 1,
      areaName: 'C & L',
      isPrime: true,
    },
  ],
  PSE: [
    {
      id: 37,
      name: 'Self-regulation',
      active: false,
      areaId: 2,
      areaName: 'PSE',
      isPrime: true,
    },
    {
      id: 36,
      name: 'Managing Self',
      active: false,
      areaId: 2,
      areaName: 'PSE',
      isPrime: true,
    },
    {
      id: 38,
      name: 'Building Relationships',
      active: false,
      areaId: 2,
      areaName: 'PSE',
      isPrime: true,
    },
  ],
  PD: [
    {
      id: 39,
      name: 'Gross Motor Skills',
      active: false,
      areaId: 3,
      areaName: 'PD',
      isPrime: true,
    },
    {
      id: 40,
      name: 'Fine Motor Skills',
      active: false,
      areaId: 3,
      areaName: 'PD',
      isPrime: true,
    },
  ],
  Literacy: [
    {
      id: 41,
      name: 'Comprehension',
      active: false,
      areaId: 4,
      areaName: 'Literacy',
      isPrime: false,
    },
    {
      id: 43,
      name: 'Word Reading',
      active: false,
      areaId: 4,
      areaName: 'Literacy',
      isPrime: false,
    },
    {
      id: 34,
      name: 'Writing',
      active: true,
      areaId: 4,
      areaName: 'Literacy',
      isPrime: false,
    },
  ],
  Maths: [
    {
      id: 18,
      name: 'Numbers',
      active: false,
      areaId: 5,
      areaName: 'Maths',
      isPrime: false,
    },
    {
      id: 42,
      name: 'Numerical Patterns',
      active: false,
      areaId: 5,
      areaName: 'Maths',
      isPrime: false,
    },
  ],
  UTW: [
    {
      id: 45,
      name: 'Past and Present',
      active: false,
      areaId: 6,
      areaName: 'UTW',
      isPrime: false,
    },
    {
      id: 44,
      name: 'People, Culture and Communication',
      active: false,
      areaId: 6,
      areaName: 'UTW',
      isPrime: false,
    },
    {
      id: 46,
      name: 'The Natural World',
      active: false,
      areaId: 6,
      areaName: 'UTW',
      isPrime: false,
    },
  ],
  EAandD: [
    {
      id: 47,
      name: 'Creating with Materials',
      active: false,
      areaId: 7,
      areaName: 'E A & D',
      isPrime: false,
    },
    {
      id: 48,
      name: 'Being Imaginative and Expressive',
      active: false,
      areaId: 7,
      areaName: 'E A & D',
      isPrime: false,
    },
  ],
};

const yearZeroReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case YearZeroActionTypes.SET_YEAR_0_SUBJECTS:
      return {
        ...state,
        CandL: action.payload.CandL,
        PSE: action.payload.PSE,
        PD: action.payload.PD,
        Literacy: action.payload.Literacy,
        Maths: action.payload.Maths,
        UTW: action.payload.UTW,
        EAandD: action.payload.EAandD,
      };

    default:
      return state;
  }
};

export default yearZeroReducer;
