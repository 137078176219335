import GradeActionTypes from './grade.types';

const INITIAL_STATE = {
  data: null,
  isFetching: false,
  errorMessage: undefined,
};

const gradeReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GradeActionTypes.POST_GRADE_START:
    case GradeActionTypes.DELETE_GRADE_START:
    case GradeActionTypes.DELETE_MULT_GRADE_START:
      return {
        ...state,
        isFetching: true,
      };

    case GradeActionTypes.POST_GRADE_SUCCESS:
    case GradeActionTypes.DELETE_GRADE_SUCCESS:
    case GradeActionTypes.DELETE_MULT_GRADE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.value,
      };
    case GradeActionTypes.POST_GRADE_ERROR:
    case GradeActionTypes.DELETE_GRADE_ERROR:
    case GradeActionTypes.DELETE_MULT_GRADE_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default gradeReducer;
