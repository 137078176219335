const FiltersActionTypes = {
  SET_SCHOOL: "SET_SCHOOL",
  SET_ACADEMIC_YEAR: "SET_ACADEMIC_YEAR",
  SET_TERM: "SET_TERM",
  SET_YEAR_GROUP: "SET_YEAR_GROUP",
  SET_CLASS: "SET_CLASS",
  SET_SUBJECT_FILTERS: "SET_SUBJECT_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
};

export const filterSubjectIds = {
  MATHEMATICS: 15,
  READING: 22,
  WRITING: 34,
  WRITING_NMM: 49,
  VOCABULARY: 33,
};
export default FiltersActionTypes;

export interface Subject {
  id: number;
  name: string;
  active: boolean;
}

export interface Group {
  id: number;
  schoolId: number;
  yearGroupId: number;
  description: string;
  name: string;
}
