// @ts-nocheck
import GradeActionTypes from './grade.types';

export const postGradeStart = () => ({
  type: GradeActionTypes.POST_GRADE_START,
});

export const postGradeSuccess = (data) => ({
  type: GradeActionTypes.POST_GRADE_SUCCESS,
  payload: data,
});

export const postGradeError = (errorMessage) => ({
  type: GradeActionTypes.POST_GRADE_ERROR,
  payload: errorMessage,
});

export const deleteGradeStart = () => ({
  type: GradeActionTypes.DELETE_GRADE_START,
});

export const deleteGradeSuccess = (data) => ({
  type: GradeActionTypes.DELETE_GRADE_SUCCESS,
  payload: data,
});

export const deleteGradeError = (errorMessage) => ({
  type: GradeActionTypes.DELETE_GRADE_ERROR,
  payload: errorMessage,
});

export const deleteMultGradeStart = () => ({
  type: GradeActionTypes.DELETE_MULT_GRADE_START,
});

export const deleteMultGradeSuccess = (data) => ({
  type: GradeActionTypes.DELETE_MULT_GRADE_SUCCESS,
  payload: data,
});

export const deleteMultGradeError = (errorMessage) => ({
  type: GradeActionTypes.DELETE_MULT_GRADE_ERROR,
  payload: errorMessage,
});
