// @ts-nocheck
import { takeEvery, all, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { fetchClassSuccess, fetchClassError } from './class.actions';

import ClassActionTypes from './class.types';

export function* fetchDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const { school, yearGroup } = payload;

  try {
    const data = yield axios.get(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/class?schools=${school}&yearGroups=${yearGroup}`,
      config
    );

    yield put(fetchClassSuccess(data));
  } catch (error) {
    yield put(fetchClassError(error.message));
  }
}

export function* fetchClassStart() {
  yield takeEvery(ClassActionTypes.FETCH_CLASS_START, fetchDataAsync);
}

export function* classSagas() {
  yield all([call(fetchClassStart)]);
}
