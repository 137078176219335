import SchoolPerformanceActionTypes from './school-performance.types';

const INITIAL_STATE = {
  fetchData: null,
  isFetching: false,
  fetchErrorMessage: undefined,

  postData: null,
  isPosting: false,
  postErrorMessage: undefined,

  editData: null,
  isEditing: false,
  editErrorMessage: undefined,
};

const schoolPerformanceReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SchoolPerformanceActionTypes.FETCH_SCHOOL_START:
      return {
        ...state,
        isFetching: true,
      };

    case SchoolPerformanceActionTypes.POST_SCHOOL_PERF_START:
      return {
        ...state,
        isPosting: true,
        postData: null,
      };

    case SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_START:
      return {
        ...state,
        isEditing: true,
        editData: null,
      };

    case SchoolPerformanceActionTypes.FETCH_SCHOOL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchData: action.payload.data.value,
      };

    case SchoolPerformanceActionTypes.POST_SCHOOL_PERF_SUCCESS:
      return {
        ...state,
        isPosting: false,
        postData: action.payload.data.value,
      };

    case SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editData: action.payload.data.value,
      };

    case SchoolPerformanceActionTypes.FETCH_SCHOOL_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchErrorMessage: action.payload,
      };

    case SchoolPerformanceActionTypes.POST_SCHOOL_PERF_ERROR:
      return {
        ...state,
        isPosting: false,
        postErrorMessage: action.payload,
      };

    case SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_ERROR:
      return {
        ...state,
        isEditing: false,
        editErrorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default schoolPerformanceReducer;
