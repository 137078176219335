import { all, call } from 'redux-saga/effects';

import { settingsSagas } from './settings/settings.sagas';
import { pupilSagas } from './pupil/pupil.sagas';
import { gradeSagas } from './grade/grade.sagas';
import { schoolSagas } from './school-performance/school-performance.sagas';
import { classSagas } from './class/class.sagas';
import { floodFillSagas } from './flood-fill/flood-fill.sagas';

export default function* rootSaga() {
  yield all([
    call(settingsSagas),
    call(pupilSagas),
    call(gradeSagas),
    call(schoolSagas),
    call(classSagas),
    call(floodFillSagas),
  ]);
}
