import styled from 'styled-components';

interface ImageProps {
  imageUrl?: string;
}

const darkGrayOne = '#535b6d';
const darkGrayTwo = '#535B6E';
const lightGray = '#889AA0';
const grayBlue = '#535B6E';
const darkPurple = '#8A6FA9';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 189px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MainHeader = styled.div`
  width: 100%;
  height: 131px;
  border-bottom-right-radius: 70px;
  background: white;
  display: flex;
  align-items: center;
`;

export const Logo = styled.div<ImageProps>`
  all: unset;
  margin-left: 2.5rem;
  background: ${(props) =>
    `url(${props.imageUrl}) no-repeat center center/cover`};
  width: 241px;
  height: 86px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  color: ${darkGrayOne};
  margin-left: 30px;
  cursor: pointer;
`;

export const User = styled.div`
  display: block;
  min-width: 210px;
  height: 60px;
  margin-left: auto;
  margin-right: 3.125rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin-right: 0.625rem;
  height: 41px;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  li:first-of-type {
    color: ${darkGrayTwo};
    height: 19px;
    margin-left: auto;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  li:nth-of-type(2) {
    color: ${lightGray};
    height: 19px;
  }
`;

export const Initials = styled.div`
  display: block;
  background: ${grayBlue};
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
`;

export const SubHeader = styled.div`
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
`;

export const Select = styled.select`
  all: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 32px;
  height: 22px;
  background-image: url("data:image/svg+xml,%3Csvg id='Drop_Down_Icon' data-name='Drop Down Icon' xmlns='http://www.w3.org/2000/svg' width='16.941' height='8.809' viewBox='0 0 16.941 8.809'%3E%3Cg id='Back_Button' data-name='Back Button' transform='translate(0 8.809) rotate(-90)'%3E%3Cg id='arrow' transform='translate(8.809 0) rotate(90)'%3E%3Cpath id='Path' d='M1.166.192A.705.705,0,0,0,.2.192a.64.64,0,0,0,0,.929l7.788,7.5a.7.7,0,0,0,.965,0l7.788-7.5a.64.64,0,0,0,0-.929.705.705,0,0,0-.965,0L8.47,7.029Z' transform='translate(0)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0em top 50%, 0 0;
  color: white;
  font-size: 18px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;

  &:nth-child(1) {
    margin-left: 2.5rem;
  }

  &:nth-child(2) {
    margin-left: 1.875rem;
  }

  option {
    color: #5f5f5f;
  }
`;

export const Button = styled.div`
  display: block;
  margin-left: auto;
  margin-right: 3.125rem;
  background: ${darkPurple};
  width: 222px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;
