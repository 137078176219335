// @ts-nocheck
import { takeEvery, all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  postGradeSuccess,
  postGradeError,
  deleteGradeSuccess,
  deleteGradeError,
  deleteMultGradeSuccess,
  deleteMultGradeError,
} from './grade.actions';

import GradeActionTypes from './grade.types';

export function* postDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    const data = yield axios.post(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
      payload,
      config
    );

    yield put(postGradeSuccess(data));
  } catch (error) {
    yield put(postGradeError(error.message));
  }
}

export function* postGradeStart() {
  yield takeLatest(GradeActionTypes.POST_GRADE_START, postDataAsync);
}

export function* deleteDataAsync({ payload, accessToken }) {
  const { Id } = payload;

  try {
    const data = yield axios.delete(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
      {
        data: { Id: Id },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    yield put(deleteGradeSuccess(data));
  } catch (error) {
    yield put(deleteGradeError(error.message));
  }
}

export function* deleteGradeStart() {
  yield takeEvery(GradeActionTypes.DELETE_GRADE_START, deleteDataAsync);
}

// delete multiple grades
export function* deleteMultDataAsync({ payload, accessToken }) {
  console.log(payload.toString());
  try {
    const data = yield axios.delete(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
      {
        data: { Ids: payload.toString() },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    yield put(deleteMultGradeSuccess(data));
  } catch (error) {
    yield put(deleteMultGradeError(error.message));
  }
}

export function* deleteMultGradeStart() {
  yield takeEvery(
    GradeActionTypes.DELETE_MULT_GRADE_START,
    deleteMultDataAsync
  );
}

export function* gradeSagas() {
  yield all([
    call(deleteGradeStart),
    call(deleteMultGradeStart),
    call(postGradeStart),
  ]);
}
