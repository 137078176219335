import SettingsActionTypes from './settings.types';
import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
  data: null,
  isFetching: false,
  errorMessage: undefined,
  terms: null,
  yearGroups: null,
  schools: null,
  academicYears: null,
  subjects: null,
  assessmentTypes: null,
  yearZeroSubjects: null,
  performanceCategories: null,
  performanceTypes: null,
};

const settingsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SettingsActionTypes.FETCH_DATA_START:
      return {
        ...state,
        isFetching: true,
      };

    case SettingsActionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.value,
        terms: action.payload.data.value.terms,
        yearGroups: action.payload.data.value.yearGroups,
        schools: action.payload.data.value.schools,
        academicYears: action.payload.data.value.academicYears,
        subjects: action.payload.data.value.subjects,
        assessmentTypes: action.payload.data.value.assessmentTypes,
        yearZeroSubjects: action.payload.data.value.yearZeroSubjects,
        performanceCategories: action.payload.data.value.performanceCategories,
        performanceTypes: action.payload.data.value.performanceTypes,
      };
    case SettingsActionTypes.FETCH_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default settingsReducer;
