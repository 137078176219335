import AccessTokenActionTypes from './access-token.types';

const INITIAL_STATE = {
  accessToken: null,
};

const accessTokenReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AccessTokenActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };

    default:
      return state;
  }
};

export default accessTokenReducer;
