// @ts-nocheck
import { takeLatest, all, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { fetchPupilSuccess, fetchPupilError } from './pupil.actions';

import PupilActionTypes from './pupil.types';

export function* fetchDataAsync({ payload, accessToken }) {
  const { school, yearGroup, term, subject, classState, academicYear } =
    payload;

  const subjects = subject.map((s) => s.id).toString();

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    const data = yield axios.get(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/pupil?schools=${school}&academicYears=${academicYear}&yearGroups=${yearGroup}&terms=${term}&subjects=${subjects}&skip=0&classes=${classState}&take=200&orderBy=adddate&orderDirection=DESC`,
      config
    );

    yield put(fetchPupilSuccess(data));
  } catch (error) {
    yield put(fetchPupilError(error.message));
  }
}

export function* fetchPupilStart() {
  yield takeLatest(PupilActionTypes.FETCH_PUPIL_START, fetchDataAsync);
}

export function* pupilSagas() {
  yield all([call(fetchPupilStart)]);
}
