import { Fragment, useEffect } from 'react';

import { useMsal, useAccount } from '@azure/msal-react';
import { protectedResources } from './authConfig';
import { useDispatch, useSelector } from 'react-redux';
import AccessTokenActionTypes from './redux/access-token/access-token.types';

import { Routes, Route } from 'react-router-dom';
import GlobalStyle from './styles/globalStyle';
import Header from './ui-components/header/index';

import Selection from './pages/selection/loadable';
import PupilTracking from './pages/pupil-tracking/loadable';
import SchoolPerformance from './pages/school-performance/loadable';

import FiltersActionTypes from './redux/filters/filters.types';
import ClassActionTypes from './redux/class/class.types';

import appRoutes from './appRoutes.ts';

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  useEffect(() => {
    if (account && inProgress === 'none') {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiLearnAt.scopes,
          account: account,
        })
        .then((response) => {
          dispatch({
            type: AccessTokenActionTypes.SET_ACCESS_TOKEN,
            payload: response,
          });
        })
        .catch((error) => {
          console.error('error', error);
          alert(error);
        });
    }
  }, [account, inProgress, instance]);

  const accessToken = useSelector((state) => state.accessToken.accessToken);
  const filters = useSelector((state) => state.filters);

  const { school, yearGroup } = filters;
  const groups = useSelector((state) => state.class.data);

  // if year group changes fetch new classes
  useEffect(() => {
    dispatch({
      type: ClassActionTypes.FETCH_CLASS_START,
      accessToken: accessToken,
      payload: { school, yearGroup },
    });

    // eslint-disable-next-line
  }, [accessToken, school, yearGroup]);

  // add default class to filters
  useEffect(() => {
    dispatch({
      type: FiltersActionTypes.SET_CLASS,
      payload: {
        ['classState']: groups.length ? groups[0]?.id?.toString() : null,
      },
    });
  }, [groups]);

  return (
    <Fragment>
      <GlobalStyle />
      <Header name={account && account.name} />
      <Pages />
    </Fragment>
  );
};

const Pages = () => (
  <Routes>
    <Route exact path={appRoutes.root} element={<Selection />} />
    <Route exact path={appRoutes.pupilTracking} element={<PupilTracking />} />
    <Route
      exact
      path={appRoutes.schoolPerformance}
      element={<SchoolPerformance />}
    />
  </Routes>
);
export default App;
