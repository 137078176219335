const GradeActionTypes = {
  POST_GRADE_START: 'POST_GRADE_START',
  POST_GRADE_SUCCESS: 'POST_GRADE_SUCCESS',
  POST_GRADE_ERROR: 'POST_GRADE_ERROR',

  DELETE_GRADE_START: 'DELETE_GRADE_START',
  DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
  DELETE_GRADE_ERROR: 'DELETE_GRADE_ERROR',

  DELETE_MULT_GRADE_START: 'DELETE_MULT_GRADE_START',
  DELETE_MULT_GRADE_SUCCESS: 'DELETE_MULT_GRADE_SUCCESS',
  DELETE_MULT_GRADE_ERROR: 'DELETE_MULT_GRADE_ERROR',
};

export default GradeActionTypes;
