import ClassActionTypes from './class.types';
import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
  data: [],
  isFetching: false,
  errorMessage: undefined,
};

const classReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ClassActionTypes.FETCH_CLASS_START:
      return {
        ...state,
        isFetching: true,
      };

    case ClassActionTypes.FETCH_CLASS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.value,
      };
    case ClassActionTypes.FETCH_CLASS_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        data: [],
      };

    case PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default classReducer;
