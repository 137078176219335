// @ts-nocheck
import SchoolPerformanceActionTypes from './school-performance.types';

export const fetchSchoolStart = () => ({
  type: SchoolPerformanceActionTypes.FETCH_SCHOOL_START,
});

export const fetchSchoolSuccess = (data, accessToken) => ({
  type: SchoolPerformanceActionTypes.FETCH_SCHOOL_SUCCESS,
  payload: data,
  accessToken: accessToken,
});

export const fetchSchoolError = (errorMessage) => ({
  type: SchoolPerformanceActionTypes.FETCH_SCHOOL_ERROR,
  payload: errorMessage,
});

export const postSchoolPerfSuccess = (data) => ({
  type: SchoolPerformanceActionTypes.POST_SCHOOL_PERF_SUCCESS,
  payload: data,
});

export const postSchoolPerfError = (errorMessage) => ({
  type: SchoolPerformanceActionTypes.POST_SCHOOL_PERF_ERROR,
  payload: errorMessage,
});

export const editSchoolPerfSuccess = (data) => ({
  type: SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_SUCCESS,
  payload: data,
});

export const editSchoolPerfError = (errorMessage) => ({
  type: SchoolPerformanceActionTypes.EDIT_SCHOOL_PERF_ERROR,
  payload: errorMessage,
});
