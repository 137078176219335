// @ts-nocheck
import { takeEvery, all, call, put } from 'redux-saga/effects';
import axios from 'axios';

import {
  floodFillSuccess,
  floodFillError,
  deleteSuccess,
  deleteError,
} from './flood-fill.actions';
import FloodFillActionTypes from './flood-fill.types';

export function* floodFillDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    let deleteRes = {};

    if (payload.deletePayload.Ids) {
      deleteRes = yield axios.delete(
        `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
        {
          data: { Ids: payload.deletePayload.Ids },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    }

    const data = yield axios.post(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
      payload.floodPayload,
      config
    );
    // const postRes = yield axios.post(
    //   `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
    //   payload.floodPayload,
    //   config
    // );

    yield put(floodFillSuccess(data));
  } catch (error) {
    yield put(floodFillError(error.message));
  }
}

export function* floodFillStart() {
  yield takeEvery(FloodFillActionTypes.FLOOD_FILL_START, floodFillDataAsync);
}

// delete multiple grades
export function* deleteDataAsync({ payload, accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    const data = yield axios.post(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/grade`,
      payload,
      config
    );

    yield put(deleteSuccess(data));
  } catch (error) {
    yield put(deleteError(error.message));
  }
}

export function* deleteStart() {
  yield takeEvery(FloodFillActionTypes.DELETE_START, deleteDataAsync);
}

export function* floodFillSagas() {
  yield all([call(floodFillStart), call(deleteStart)]);
}
