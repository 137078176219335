/**
 * * Application Routes
 *
 * This file should contain all the
 * available routes.
 */

const appRoutes: AppRoutes = {
  root: '/',
  examples: '/examples',
  pupilTracking: '/pupil-tracking',
  schoolPerformance: '/school-performance',
  hello: '/hello',
};

interface AppRoutes {
  readonly root: string;
  readonly examples: string;
  readonly pupilTracking: string;
  readonly schoolPerformance: string;
  readonly hello: string;
}

export default appRoutes;
