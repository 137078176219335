import FiltersActionTypes from "./filters.types";
import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  academicYear: null,
  school: null,
  term: null,
  yearGroup: null,
  classState: null,
  subject: [],
};

const filtersReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FiltersActionTypes.SET_ACADEMIC_YEAR:
      return {
        ...state,
        academicYear: action.payload.academicYear,
      };

    case FiltersActionTypes.SET_SCHOOL:
      return {
        ...state,
        school: action.payload.school,
      };

    case FiltersActionTypes.SET_TERM:
      return {
        ...state,
        term: action.payload.term,
      };

    case FiltersActionTypes.SET_YEAR_GROUP:
      return {
        ...state,
        yearGroup: action.payload.yearGroup,
      };

    case FiltersActionTypes.SET_CLASS:
      return {
        ...state,
        classState: action.payload.classState,
      };

    case FiltersActionTypes.SET_SUBJECT_FILTERS:
      return {
        ...state,
        subject: action.payload,
      };

    case FiltersActionTypes.RESET_FILTERS:
      return {
        academicYear: null,
        school: null,
        term: null,
        yearGroup: null,
        classState: null,
        subject: [],
      };

    case PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default filtersReducer;
