// @ts-nocheck
import ClassActionTypes from './class.types';

export const fetchClassStart = () => ({
  type: ClassActionTypes.FETCH_CLASS_START,
});

export const fetchClassSuccess = (data) => {
  data.data.value.forEach((el) => (el.name = el.description));

  return {
    type: ClassActionTypes.FETCH_CLASS_SUCCESS,
    payload: data,
  };
};

export const fetchClassError = (errorMessage) => ({
  type: ClassActionTypes.FETCH_CLASS_ERROR,
  payload: errorMessage,
});
