// @ts-nocheck
import { takeEvery, all, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { fetchDataSuccess, fetchDataError } from './settings.actions';

import SettingsActionTypes from './settings.types';

export function* fetchDataAsync({ accessToken }) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    const data = yield axios.get(
      `${process.env.REACT_APP_LEARN_AT_API_ENDPOINT}/api/settings`,
      config
    );

    yield put(fetchDataSuccess(data));
  } catch (error) {
    yield put(fetchDataError(error.message));
  }
}

export function* fetchDataStart() {
  yield takeEvery(SettingsActionTypes.FETCH_DATA_START, fetchDataAsync);
}

export function* settingsSagas() {
  yield all([call(fetchDataStart)]);
}
