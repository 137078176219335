const FloodFillActionTypes = {
  FLOOD_FILL_START: 'FLOOD_FILL_START',
  FLOOD_FILL_SUCCESS: 'FLOOD_FILL_SUCCESS',
  FLOOD_FILL_ERROR: 'FLOOD_FILL_ERROR',

  DELETE_START: 'DELETEL_START',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',
};

export default FloodFillActionTypes;
