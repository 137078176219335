const ClassActionTypes = {
  FETCH_CLASS_START: 'FETCH_CLASS_START',
  FETCH_CLASS_SUCCESS: 'FETCH_CLASS_SUCCESS',
  FETCH_CLASS_ERROR: 'FETCH_CLASS_ERROR',
};

export default ClassActionTypes;

export interface Class {
  id: number;
  schoolId: number;
  yearGroupId: number;
  description: string;
  name: string;
}
