const SchoolPerformanceActionTypes = {
  FETCH_SCHOOL_START: 'FETCH_SCHOOL_START',
  FETCH_SCHOOL_SUCCESS: 'FETCH_SCHOOL_SUCCESS',
  FETCH_SCHOOL_ERROR: 'FETCH_SCHOOL_ERROR',

  POST_SCHOOL_PERF_START: 'POST_SCHOOL_PERF_START',
  POST_SCHOOL_PERF_SUCCESS: 'POST_SCHOOL_PERF_SUCCESS',
  POST_SCHOOL_PERF_ERROR: 'POST_SCHOOL_PERF_ERROR',

  EDIT_SCHOOL_PERF_START: 'EDIT_SCHOOL_PERF_START',
  EDIT_SCHOOL_PERF_SUCCESS: 'EDIT_SCHOOL_PERF_SUCCESS',
  EDIT_SCHOOL_PERF_ERROR: 'EDIT_SCHOOL_PERF_ERROR',
};

export default SchoolPerformanceActionTypes;
