import ToggleActionTypes from './toggle.types';

const INITIAL_STATE = {
  teacherAssessments: true,
  testScores: false,
};

const toggleReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ToggleActionTypes.TEACHER_ASSESSMENTS_ON:
      return {
        teacherAssessments: true,
        testScores: false,
      };

    case ToggleActionTypes.TEST_SCORES_ON:
      return {
        teacherAssessments: false,
        testScores: true,
      };

    default:
      return state;
  }
};

export default toggleReducer;
