// @ts-nocheck
import PupilActionTypes from './pupil.types';

export const fetchPupilStart = () => ({
  type: PupilActionTypes.FETCH_PUPIL_START,
});

export const fetchPupilSuccess = (data) => ({
  type: PupilActionTypes.FETCH_PUPIL_SUCCESS,
  payload: data,
});

export const fetchPupilError = (errorMessage) => ({
  type: PupilActionTypes.FETCH_PUPIL_ERROR,
  payload: errorMessage,
});
